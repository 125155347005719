import { EN } from './config/languages'

export const LS_KEY = 'swap_language'

export const fetchLocale = async (locale: string) => {
  return import(`./config/${locale}.json`)
}

export const getLanguageCodeFromLS = () => {
  try {
    const codeFromStorage = localStorage.getItem(LS_KEY)

    return codeFromStorage || EN.locale
  } catch {
    return EN.locale
  }
}
