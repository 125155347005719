import React from "react";
import { SvgProps } from "../types";
import {useTheme} from "next-themes";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  const { resolvedTheme } = useTheme()
  const isDark = resolvedTheme === 'dark'

  return (
    <img src={`/images/home/home/logo${isDark ? '' : '-light'}.png`} alt='' {...props} />
  );
};

export default Icon;
