import {
  MenuItemsType,
  DropdownMenuItems, SwapFillIcon, DropdownMenuItemType,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'
import { Icon1, Icon2, Icon3, Icon4 } from 'components/Menu/config/MenuIcon'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & {
  hideSubNav?: boolean
}
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & {
  hideSubNav?: boolean;
  image?: string
} & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Swap'),
      href: 'swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955&inputCurrency=BNB',
      items: [],
      showItemsOnMobile: false,
      icon: Icon1,
    },
    {
      label: t('Liquidity'),
      href: '/liquidity',
      items: [],
      showItemsOnMobile: false,
      icon: Icon2,
    },
    {
      label: `${t('Limit')} (V2)`,
      href: '/limit-orders',
      supportChainIds: SUPPORT_ONLY_BSC,
      items: [],
      showItemsOnMobile: false,
      icon: Icon3,
    },
    {
      label: t('Buy Crypto'),
      href: '/buy-crypto',
      items: [],
      showItemsOnMobile: false,
      icon: Icon4,
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
