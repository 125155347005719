import React from 'react'
import { useTheme } from 'next-themes'

const Logo: React.FC = (props: any) => {
  const { resolvedTheme } = useTheme()
  const isDark = props.isDark ? true : resolvedTheme === 'dark'

  return (
    <div className={(`flex items-center ${props.className}`)}>
      <img src={`/images/home/home/logo${isDark ? '' : '-light'}.png`} alt='' style={{ width: 144 }} />
    </div>
  )
}

export default Logo
