import {DefaultSeoProps} from 'next-seo'

export const SEO: DefaultSeoProps = {
    titleTemplate: '%s | TUP swap',
    defaultTitle: 'TUP swap',
    description: '',
    twitter: {
        // cardType: 'summary_large_image',
        // handle: '@PancakeSwap',
        // site: '@PancakeSwap',
    },
    openGraph: {
        title: 'TUPSwap',
        description: 'The most popular AMM on BSC by user count!',
        images: [{url: 'http://223.255.240.51/images/home/home/hero.png'}],
    },
}
