import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }

  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  #__next {
    position: relative;
    z-index: 1;
  }

  #portal-root {
    position: relative;
    z-index: 2;
  }

  .main {
    padding: 0 20px;

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 0;
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  .flex {
    display: flex;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }
  
  .items-center {
    align-items: center;
  }

  .justify-between {
    justify-content: space-between
  }

  .pc-show {
    display: none;

    ${({ theme }) => theme.mediaQueries.lg} {
      display: block;
    }
  }
  
  .mobile-show {
    display: none;

    ${({ theme }) => theme.mediaQueries.mobile} {
      display: block;
    }
  }

  @font-face {
    font-family: "IMPACT";
    src: url("/font/IMPACT.TTF");
  }

  .font-impact {
    font-family: "IMPACT";
  }
`

export default GlobalStyle
